import AppHeader from './../../components/AppHeader.vue';
import Main from './../../components/Main.vue';
// import Watermark from './../../components/Watermark.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    Main
    // Watermark
  }
};