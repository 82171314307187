export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    },
    title: '',
    svgPreUrl: ''
  },
  setup(props) {
    return {
      title: props.title,
      list: props.list,
      svgPreUrl: props.svgPreUrl
    };
  }
};