import { createApp } from 'vue'
import App from './App.vue'
import { Lazyload } from 'vant';

function resetRootFZ() {
    var htmlDom = document.querySelector('html')
    var w = htmlDom.getBoundingClientRect().width // px（ 屏幕的总宽度）
    htmlDom.style.fontSize = w / 20 + 'px'
}

resetRootFZ()
window.addEventListener('resize', function () {
    resetRootFZ()
})


const app = createApp(App);
app.use(Lazyload);
app.mount('#app');
