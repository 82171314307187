import "core-js/modules/es.array.push.js";
import TopGame from './game-list/TopGame.vue';
import SimilarGame from './game-list/SimilarGame.vue';
import goBack from './goBack.vue';
import MainDesc from './MainDesc.vue';
import FooterI from './FooterI.vue';
import { ref, onMounted } from 'vue';
import { appListMapByCategory, urlConfig } from '@/common/index.js';

// const configList = configJson;

export default {
  components: {
    FooterI,
    TopGame,
    SimilarGame,
    goBack,
    MainDesc
  },
  setup() {
    const gameList = ref({});
    const AdList = ref(window._config.homeAdConfig);
    function categorizeByCategory(items, imgUrlPrefixed) {
      return items.reduce((acc, item) => {
        // 如果累加器中还没有当前项的 category，就初始化一个数组
        if (!acc[item.category]) {
          acc[item.category] = [];
        }
        // 将当前项添加到对应 category 的数组中
        acc[item.category].push({
          ...item,
          imageUrl: `${imgUrlPrefixed}${item.image}`
        });
        return acc;
      }, {});
    }
    function createObserver(idTarget, threshold = 1, callback = () => {}) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 目标元素出现在视口中
            callback && callback();
            // 如果你只想触发一次，可以在这里取消观察
            observer.unobserve(entry.target);
          }
        });
      }, {
        root: null,
        // 视口作为观察区域
        rootMargin: '0px',
        threshold: threshold // 目标元素至少有10%出现在视口时触发
      });
      if (document.getElementById(idTarget)) {
        observer.observe(document.getElementById(idTarget));
      }
    }
    onMounted(() => {
      Object.keys(appListMapByCategory).map((item, index) => {
        console.log("index", index);
        if (window._config.homeAdConfig[index]) {
          console.log("创建---", index);
          const {
            threshold = 1,
            callback
          } = window._config.homeAdConfig[index];
          createObserver(`targetElement${index}`, threshold, callback);
        }
      });
    });
    gameList.value = appListMapByCategory;
    console.log("gameList", gameList.value);
    return {
      gameList,
      svgPreUrl: urlConfig.preIconUrl,
      AdList
    };
  }
};