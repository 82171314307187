import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "mid_content"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = ["id"];
const _hoisted_7 = ["id", "innerHTML"];
const _hoisted_8 = ["id", "innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TopGame = _resolveComponent("TopGame");
  const _component_SimilarGame = _resolveComponent("SimilarGame");
  const _component_MainDesc = _resolveComponent("MainDesc");
  const _component_FooterI = _resolveComponent("FooterI");
  const _component_goBack = _resolveComponent("goBack");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.gameList, (list, key, index) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: index
    }, [index === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
      id: "adTopElement0",
      innerHTML: $setup.AdList[index]?.topHtml || ''
    }, null, 8, _hoisted_4), _createVNode(_component_TopGame, {
      id: "targetElement0",
      title: key,
      svgPreUrl: $setup.svgPreUrl,
      list: list
    }, null, 8, ["title", "svgPreUrl", "list"]), _createElementVNode("div", {
      id: "adBottomElement0",
      innerHTML: $setup.AdList[index]?.bottomHtml || ''
    }, null, 8, _hoisted_5)])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      id: `targetElement${index}`
    }, [_createElementVNode("div", {
      id: `adTopElement${index}`,
      innerHTML: $setup.AdList[index]?.topHtml || ''
    }, null, 8, _hoisted_7), _createVNode(_component_SimilarGame, {
      title: key,
      svgPreUrl: $setup.svgPreUrl,
      list: list
    }, null, 8, ["title", "svgPreUrl", "list"]), _createElementVNode("div", {
      id: `adBottomElement${index}`,
      innerHTML: $setup.AdList[index]?.bottomHtml || ''
    }, null, 8, _hoisted_8)], 8, _hoisted_6))], 64);
  }), 128)), _createVNode(_component_MainDesc), _createVNode(_component_FooterI), _createVNode(_component_goBack)])]);
}